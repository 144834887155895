import React, { Component } from "react";

import "./VerticalText.scss";

type Align = "left" | "right";

interface IVerticalTextProps {
    align: Align;
}

export default class VerticalText extends Component<IVerticalTextProps, {}> {
    render() {
        return (
            <div className={"vertical-text " + this.props.align + "-aligned"}>
                {this.props.children}
            </div>
        );
    }
}