import React, { Component } from "react";

import { BrowserRouter, Switch } from 'react-router-dom';

import Route from './modules/RouteModule';

import SinglePageLayout from './layouts/SinglePageLayout';

import SinglePage from './pages/SinglePage';

import './App.scss';

import './themes/dark-theme.scss';

export class App extends Component<{}, {}> {
    render() {
        return (
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <Switch>
                    <Route layout={SinglePageLayout} path="/" component={SinglePage} exact/>
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;