import React, { Component } from "react";

import "./Section.scss";

class Hero extends Component<{}, {}> {
    render() {
        return (
            <div className="section-hero">
                {this.props.children}
            </div>
        );
    }
}

class Title extends Component<{}, {}> {
    render() {
        return (
            <div className="section-title">
                {this.props.children}
            </div>
        );
    }
}

class Body extends Component<{}, {}> {
    render() {
        return (
            <div className="section-body">
                {this.props.children}
            </div>
        );
    }
}

interface ISectionProps {
    id: string;
}

class Section extends Component<ISectionProps, {}> {
    public static Hero: typeof Hero = Hero;
    public static Title: typeof Title = Title;
    public static Body: typeof Body = Body;

    render() {
        return (
            <section id={this.props.id}>
                {this.props.children}
            </section>
        );
    }
}

export default Section;