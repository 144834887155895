import React, { Component } from "react";

import HomePage from './home/HomePage';
// import ProjectsPage from './projects/ProjectsPage';
// import AboutPage from './about/AboutPage';
// import ContactPage from './contact/ContactPage';

import "./SinglePage.scss";

export default class SinglePage extends Component<{}, {}> {

    render() {
        return (
            <React.Fragment>
                <HomePage/>
            </React.Fragment>
        );
    }
}