import React, { Component } from "react";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import "./Header.scss";

export default class Header extends Component<{}, {}> {
    render() {
        return (
            <header>
                <Navbar fixed="top" variant="dark" className="container">
                    <Navbar.Brand href="/#home">
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 135.96 117.81">
                            <path d="M15.42,117.73,0,117.76,28.05,68.91s31,.15,31-.19S43.74,42.29,43.74,41.79,68.22,0,68.22,0l34.54,59.66-13.83-.08L68.26,24.24,57.8,40.87,72.88,68.74l35,.09,28.12,49H123.23l-7.76-12.18-14.71-25L35,80.74,21.25,106Z"/>
                        </svg>
                        <span>
                            <p className="name">Alex Antonides</p>
                            <p className="title">Portfolio Page</p>
                        </span>
                    </Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <Nav>
                            <Nav.Link href="/#projects">Projects</Nav.Link>
                            <Nav.Link href="/#about">About</Nav.Link>
                            <Nav.Link href="/#contact">Contact</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </header>
        );
    }
}