import React, { Component } from "react";

import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

export interface ISinglePageProps {
}

export default class SinglePageLayout extends Component<{}, {}> {
    render() {
        return (
            <React.Fragment>
                <Header/>

                <main>
                    {this.props.children}
                </main>

                <Footer/>
            </React.Fragment>
        );
    }
}