import React, { Component } from "react";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import { FaInstagram, FaGithub, FaGitlab, FaLinkedin } from 'react-icons/fa';

import "./Footer.scss";

export default class Footer extends Component<{}, {}> {
    render() {
        return (
            <footer>
                <Navbar fixed="bottom" variant="dark" className="container">
                    <Navbar.Collapse className="justify-content-end">
                        <Nav>
                            <Nav.Link href="https://www.instagram.com/Alex_Antonides/" target="_blank"><FaInstagram/></Nav.Link>
                            <Nav.Link href="https://github.com/AlexAntonides" target="_blank"><FaGithub/></Nav.Link>
                            <Nav.Link href="https://gitlab.com/AlexAntonides" target="_blank"><FaGitlab/></Nav.Link>
                            <Nav.Link href="https://www.linkedin.com/in/AlexAntonides/" target="_blank"><FaLinkedin/></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </footer>
        );
    }
}