import React, { Component } from "react";

import Container from "react-bootstrap/Container";

import Section from "../../components/section/Section";
import VerticalText from "../../components/section/heroes/vertical_text/VerticalText";
import ParticleSpace from "../../components/section/heroes/particle_space/ParticleSpace";

import { ISinglePageProps } from "../../layouts/SinglePageLayout";

import "./HomePage.scss";

export default class HomePage extends Component<ISinglePageProps, {}> {
    render() {
        return (
            <Section id="home">
                <Container>
                    <Section.Hero>
                        <VerticalText align="right">
                            <h1>Alex Antonides</h1>
                        </VerticalText>
                        <ParticleSpace/>
                    </Section.Hero>
                    <Section.Body>
                        <h1>
                            <span>Alex</span>
                            <br/>
                            <span>Antonides</span>
                        </h1>
                        <p>
                            Trainee Software Engineer
                        </p>
                    </Section.Body>
                </Container>
            </Section>
        );
    }
}