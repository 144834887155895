import React, { Component } from "react";

import * as THREE from "three";

import "./ParticleSpace.scss";

export default class ParticleSpace extends Component<{}, {}> {
    private parent?: any;

    private renderer?: any; 
    private scene?: any; 
    private camera?: any;

    private geometry?: any; 
    private material?: any; 
    private mesh?: any;

    private particleCount: number = 10000; 
    private particles?: any; 
    private pMap?: any;
    private pMaterial?: any;
    private pSystem?: any;

    private rotationSpeedX: number = 2.5; 
    private rotationSpeedY: number = 2.5;  
    private rotationSpeedZ: number = 2.5; 

    componentDidMount() {
        this.initializeRenderer();
        this.initializeParticles();

        window.addEventListener('resize', () => {
            this.camera.aspect = window.innerWidth / window.innerHeight;
            this.camera.updateProjectionMatrix();
            
            this.renderer.setSize(this.parent.offsetWidth + 40, this.parent.offsetHeight);
            this.renderer.setPixelRatio( window.devicePixelRatio );
            this.renderer.setClearColor( 0x000000, 0 );
        });
    }

    private initializeRenderer = () => {
        this.parent = document.getElementById('webgl');

        if (this.parent) {
            this.renderer = new THREE.WebGLRenderer( { alpha: true } );
            this.renderer.domElement.setAttribute('class', 'WebGL-Intro');
            this.renderer.setSize(this.parent.offsetWidth, this.parent.offsetHeight);
            this.renderer.setPixelRatio( window.devicePixelRatio );
            this.renderer.setClearColor( 0x000000, 0 );
            
            this.camera = new THREE.PerspectiveCamera( 50, window.innerWidth / window.innerHeight, 1, 5000 );
            this.camera.position.z = 140;
            
            this.scene = new THREE.Scene();
            this.scene.add(this.camera);
            
            this.parent.append(this.renderer.domElement);
        }
    }

    private initializeParticles = () => {
        // 0x6E7C94 particle color
        this.particles = new THREE.Geometry();
        
        //this.pMap = new THREE.ImageUtils.loadTexture('circle.png');
        const loader = new THREE.TextureLoader();

        loader.load('circle.png', (texture: any) => {
            this.pMap = texture;
         
            this.pMaterial = new THREE.PointsMaterial({ 
                color: 0x6E7C94, 
                size: 1,
                sizeAttenuation: true,
                map: this.pMap,
                transparent: true
            });
            
            for ( var p = 0; p < this.particleCount; p++) {
                var particle = new THREE.Vector3(Math.random() * 500 - 250, Math.random() * 500 - 250, Math.random() * 500 - 250);
                this.particles.vertices.push(particle);
            }
            
            this.pSystem = new THREE.Points(this.particles, this.pMaterial);
            this.scene.add(this.pSystem);

            this.tick();
        })
    }

    private tick = () => {
        this.animate();
        this.renderRenderer();

        requestAnimationFrame(this.tick);
    }

    private animate = () =>{
        this.pSystem.rotation.x += this.rotationSpeedX / 1000;
        this.pSystem.rotation.y += this.rotationSpeedY / 1000;
        this.pSystem.rotation.z += this.rotationSpeedZ / 1000;
    }

    private renderRenderer = () => {
        this.renderer.render(this.scene, this.camera);
    }

    render() {
        return (
            <div id="webgl"></div>
        );
    }
}